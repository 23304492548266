<template>
  <div>
    <b-row class="justify-content-end">
      <b-col class="d-flex justify-content-end align-items-center">
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-500 form-filter">
            <b-container class="p-0">
              <b-row>
                <b-col cols="6">
                  <basic-select
                    label="Gói dịch vụ"
                    placeholder="Gói hiện hành"
                    name="package"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="packages"
                    :value.sync="filter.selectedPackages"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Tuần"
                    placeholder="Tuần lộ trình"
                    name="package"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="agendaWeeks"
                    :value.sync="filter.selectedWeeks"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Cấp độ"
                    placeholder="Cấp độ"
                    name="level"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="levels"
                    :value.sync="filter.selectedLevels"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Chủ đề"
                    placeholder="Chủ đề"
                    name="module"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="modules"
                    :value.sync="filter.selectedModules"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Trạng thái"
                    placeholder="Trạng thái"
                    name="status"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="statuses"
                    :value.sync="filter.selectedStatuses"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Loại bài học"
                    placeholder="Loại bài học"
                    name="type"
                    :solid="false"
                    changeValueByObject
                    multiple
                    trackBy="value"
                    valueLabel="text"
                    :options="lessonTypes"
                    :value.sync="filter.selectedLessonTypes"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-around align-items-center">
        <div
          class="border-left w-50 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div class="progress__value schedule__lesson">
            {{ totalCompletedRoadmapLesson }}/{{ totalRoadmapLesson }}
          </div>
          <div class="text-center fs-12 schedule__lesson">Bài học lộ trình</div>
        </div>
        <div
          class="border-left w-50 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div class="progress__value optional__lesson">
            {{ totalCompleteLessonOption }}
          </div>
          <div class="text-center fs-12 optional__lesson">Bài học tùy chọn</div>
        </div>
      </b-col>
    </b-row>
    <div class="py-8">
      <template-table
        :data="lessons"
        :column="headers"
        :selectAction="false"
        :tableAction="false"
        :searchAction="false"
        :paging="paging"
      >
        <template v-slot:body="{ item }">
          <td>{{ item.code }}</td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.name }}
            </div>
          </td>
          <td>{{ getLessonType(item.type) }}</td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.lessonModule }}
            </div>
          </td>
          <td class="text-center">{{ item.lessionLevel }}</td>
          <td class="text-center">{{ item.week }}</td>
          <td>
            <div class="status" :class="getClassByStatus(item.learningStatus)">
              {{ getSatus(item) }}
            </div>
          </td>
          <td>
            {{ item.package }}
          </td>
          <td class="text-center">
            {{ convertTimestampToDate(item.finishedDate) }}
          </td>
          <td class="text-center">{{ item.totalTimes }}</td>
          <td class="text-center">
            {{ item.totalCorrectAnswer }}/{{ item.totalAnswer }}
          </td>
          <td class="text-center">
            <b-button
              v-if="
                isShowQuizResult(item.learningStatus) &&
                item.quizLessons &&
                item.quizLessons.length
              "
              class="btn btn-view"
              size="sm"
              @click="openModalQuiz(item)"
            >
              <span class="svg-icon mr-0">
                <inline-svg src="/media/svg/icons/Neolex/Basic/eye.svg" />
              </span>
            </b-button>
          </td>
        </template>
      </template-table>
    </div>
    <ModalQuizResult
      v-if="showModalQuizResult"
      :show="showModalQuizResult"
      :id="selectedLesson.id"
      @reset="resetModal"
    />
  </div>
</template>
<script>
import { LESSON_ACCOUNT_TYPES } from '../../../../core/plugins/constants';
export default {
  name: 'Lesson',
  components: {
    ModalQuizResult: () => import('../components/ModalQuizResult.vue'),
  },
  data() {
    return {
      showModalQuizResult: false,
      lessons: [],
      headers: [
        {
          label: 'Mã',
          class: 'text-left',
          key: 'code',
          style: {
            width: '60px',
            'min-width': '60px',
          },
        },
        {
          label: 'Tên bài học',
          class: 'text-left',
          key: 'name',
          style: {
            width: '250px',
            'min-width': '250px',
          },
        },
        {
          label: 'Loại bài học',
          class: 'text-left',
          key: 'type',
          style: {
            width: '110px',
            'min-width': '110px',
          },
        },
        {
          label: 'Chủ đề',
          class: 'text-left',
          key: 'module',
          style: {
            width: '120px',
            'min-width': '120px',
          },
        },
        {
          label: 'Cấp độ',
          class: 'text-center',
          key: 'level',
          style: {
            width: '90px',
            'min-width': '90px',
          },
        },
        {
          label: 'Tuần học',
          class: 'text-center',
          key: 'week',
          style: {
            width: '90px',
            'min-width': '90px',
          },
        },
        {
          label: 'Trạng thái học',
          class: 'text-left',
          key: 'status',
          style: {
            width: '130px',
            'min-width': '130px',
          },
        },
        {
          label: 'Gói dịch vụ',
          class: 'text-left',
          key: 'package',
          style: {
            width: '140px',
            'min-width': '140px',
          },
        },
        {
          label: 'Ngày hoàn thành',
          class: 'text-center',
          key: 'finishDate',
          style: {
            width: '140px',
            'min-width': '140px',
          },
        },
        {
          label: 'Số lần làm quiz',
          class: 'text-center',
          key: 'retestQuiz',
          style: {
            width: '130px',
            'min-width': '130px',
          },
        },
        {
          label: 'Điểm làm quiz',
          class: 'text-center',
          key: 'scoreQuiz',
          style: {
            width: '130px',
            'min-width': '130px',
          },
        },
        {
          label: 'Xem kết quả quiz',
          class: 'text-center',
          key: 'quizResult',
          style: {
            width: '140px',
            'min-width': '140px',
          },
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      statuses: [],
      packages: [],
      levels: [],
      modules: [],
      lessonTypes: [],
      selectedLesson: null,
      agendaWeeks: [],
      filter: {
        selectedPackages: [],
        selectedWeeks: [],
        selectedLevels: [],
        selectedModules: [],
        selectedStatuses: [],
        selectedLessonTypes: [],
      },
      totalRoadmapLesson: 0,
      totalCompletedRoadmapLesson: 0,
      totalCompleteLessonOption: 0,
    };
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
    searchParams() {
      const filter = {
        patientId: this.patientId,
        page: this.paging.page,
        size: this.paging.pageSize,
      };

      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }

      if (this.filter.selectedPackages?.length) {
        this.filter.selectedPackages.forEach((el) => {
          params.append('packageIds', el.value);
        });
      }
      if (this.filter.selectedWeeks?.length) {
        this.filter.selectedWeeks.forEach((el) => {
          params.append('weeks', el.value);
        });
      }
      if (this.filter.selectedLevels?.length) {
        this.filter.selectedLevels.forEach((el) => {
          params.append('lessonLevelIds', el.value);
        });
      }
      if (this.filter.selectedModules?.length) {
        this.filter.selectedModules.forEach((el) => {
          params.append('lessonModuleIds', el.value);
        });
      }
      if (this.filter.selectedStatuses?.length) {
        this.filter.selectedStatuses.forEach((el) => {
          params.append('lessonStateIds', el.value);
        });
      }
      if (this.filter.selectedLessonTypes?.length) {
        this.filter.selectedLessonTypes.forEach((el) => {
          params.append('types', el.value);
        });
      }
      return params;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  async created() {
    await Promise.all([
      this.getPrepareForSeach(),
      this.loadData(),
      this.getTotalRoadmap(),
    ]);
  },
  methods: {
    openModalQuiz(lesson) {
      this.selectedLesson = lesson;
      this.showModalQuizResult = true;
    },
    resetModal() {
      this.selectedLesson = null;
      this.showModalQuizResult = false;
    },
    searchRequest() {
      document.body.click();
      this.loadData();
    },
    resetRequest() {
      this.filter = {
        selectedPackages: [],
        selectedWeeks: [],
        selectedLevels: [],
        selectedModules: [],
        selectedStatuses: [],
        selectedLessonTypes: [],
      };
      this.paging = {
        page: 1,
        pageSize: 10,
        total: 0,
      };
      document.body.click();
      this.loadData();
    },
    async loadData() {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Lesson',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.lessons = data?.items || [];
        this.paging.total = data?.total || 0;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async getPrepareForSeach() {
      try {
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Lesson/Filter-Form/${this.patientId}`,
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.packages = data.packages;
        this.levels = data.lessonLevels;
        this.modules = data.lessonModules;
        this.statuses = data.lessonStates;
        this.lessonTypes = data.lessonTypes;
        this.agendaWeeks = data.agendaWeeks;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getTotalRoadmap() {
      try {
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Lesson/Statistic/${this.patientId}`,
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.totalRoadmapLesson = data.totalRoadMapLesson;
        this.totalCompletedRoadmapLesson = data.totalCompleteRoadMapLesson;
        this.totalCompleteLessonOption = data.totalCompleteLessonOption;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    getSatus(lesson) {
      switch (lesson.learningStatus) {
        case LESSON_ACCOUNT_TYPES.NOT_COMPLETE:
          return 'Chưa học';
        case LESSON_ACCOUNT_TYPES.COMPLETE:
          return 'Hoàn thành';
        case LESSON_ACCOUNT_TYPES.LOCK:
          return 'Chưa mở khóa';
        case LESSON_ACCOUNT_TYPES.STUDYING:
          return `Đang học ${lesson.percentComplete}%`;

        default:
          break;
      }
    },
    getClassByStatus(status) {
      switch (status) {
        case LESSON_ACCOUNT_TYPES.NOT_COMPLETE:
          return 'not-complete';
        case LESSON_ACCOUNT_TYPES.COMPLETE:
          return 'completed';
        case LESSON_ACCOUNT_TYPES.LOCK:
          return 'lock';
        case LESSON_ACCOUNT_TYPES.STUDYING:
          return `completing`;

        default:
          break;
      }
    },
    getLessonType(type) {
      if (type === 1) {
        return 'Bắt buộc';
      }

      if (type === 2) {
        return 'Tùy chọn';
      }
      return 'Quiz cấp độ';
    },
    isShowQuizResult(sattus) {
      return sattus === LESSON_ACCOUNT_TYPES.COMPLETE;
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-500 {
  min-width: 500px;
}

.progress__value {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.schedule__lesson {
  color: #407bff;
}
.optional__lesson {
  color: #4bb2ab;
}

.fs-12 {
  font-size: 12px;
}

.btn-view::v-deep {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;

  font-size: 12px;
  line-height: 16px;
  color: #ffffff;

  &.completed {
    background: #008479;
  }

  &.not-complete {
    background: #f58220;
  }

  &.completing {
    background: #21a567;
  }

  &.lock {
    background: #888c9f;
  }
}
</style>
